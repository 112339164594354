
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import {
  CustomCategoryItem,
} from '@/entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryBulkOperations } from '@/entities/BulkOperations/CustomCategory/CustomCategoryBulkOperations';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import CustomCategoryAssignFolders from '@/components/CustomCategory/BatchOperations/CustomCategoryAssignFolders.vue';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { CustomCategoryFolder } from '../../../entities/CustomCategory/CustomCategoryFolder';
import AddProgramWithEntity from '@/components/AddProgram/AddProgramWithEntity.vue';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import ModuleEnum from '@/enums/ModuleEnum';
import { Root, Store } from '@/main';
@Component({
  components:
  {
    ModalPopup,
    ImageProcess,
    CustomCategoryAssignFolders,
    AddProgramWithEntity,
  },
})
class CustomCategoryBatchEditPane extends Vue {
  @Prop() private selectedItems?: CustomCategoryItem[];
  @Prop() private folderItems?: CustomCategoryFolder[];
  @Prop() private availableProgramItems?: ProgramItem[];
  private bulkEditOperations: CustomCategoryBulkOperations = new CustomCategoryBulkOperations(
    Root,
    Store,
    this.$t,
  );
  private signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
    Root,
    Store,
    this.$t,
  );
  private selectedCustomCategoryIds: number[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showCustomCategoryBatchImagesPane = false;
  private showAssignToFolderBatchPane = false;
  private readonly BulkDeleteOperation = 'BulkDeleteOperation';
  private readonly missingImage: string = require('@/assets/Images/ImageMissing.png');
  private readonly featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private readonly CustomCategoryModule = 'CustomCategory';
  private showAddProgramnBatchPane = false;
  private localAllPrograms?: ProgramItem[] = [];
  private showSection_ModalPopup = '';
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private EnableLinkParticipantToInformation = false;
  private isEnableFeaturedImage = false;
  private selectedItemsIds: number[] = [];
  private entityType = ModuleEnum.Information;
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  // Handle calling of Functions from Click Events in the List
  private HandleFunctionCall(
    objModel: SignalRModelRequest,
    confirmationMessage: string,
    showConfirmationPopup: boolean,
  ) {
    if (showConfirmationPopup) {
      this.selectedCustomCategoryIds = [];
      this.selectedItems!.forEach((element) => {
        this.selectedCustomCategoryIds.push(element.CustomCategoryId);
      });
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.BulkDeleteOperation;
      this.modalPopupContent = this.$t(confirmationMessage, {
        selectedCount: this.selectedItems!.length,
      }).toString();
    } else {
      if (objModel.SubModule === SubModuleEnum.AssignToFolder) {
        this.ShowAssignToFolderBatchPane(true);
      } else if (objModel.SubModule === SubModuleEnum.EditIconsAndImages) {
        this.ShowBatchImagesPane(true);
      } else if (objModel.SubModule === SubModuleEnum.LinkProgram) {
        this.ShowAddProgramBatchPane(true);
      }
    }
    this.localSelectedBulkOperationModel = Object.assign({}, objModel);
  }
  private mounted(){
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    this.EnableLinkParticipantToInformation = appInfo.EnableLinkParticipantToInformation;
    this.isEnableFeaturedImage = !this.EnableLinkParticipantToInformation;
    if (this.selectedItems !== null && this.selectedItems !== undefined) {
      this.selectedItems.forEach((element) => {
        this.selectedItemsIds.push(element.CustomCategoryId);
      });
    }
  }
  // Confirm to perform batch operation
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.localSelectedBulkOperationModel.SelectedIds = this.selectedCustomCategoryIds;
      this.signalR.PerformCustomCategoryBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('customCategory-uncheck-select-all');
    } else {
      return;
    }
  }
  private ShowAddProgramBatchPane(val: boolean) {
    this.showAddProgramnBatchPane = val;
    if (val) {
      this.GetAddProgramComponentData();
    }
  }
  private GetAddProgramComponentData() {
    this.localAllPrograms =  this.availableProgramItems || [];
  }
  // To show program lisiting to link a program
  private showAttachProgram() {
    this.ShowAddProgramBatchPane(false);
  }
  // To show ImageProcess to add/ update image/icon
  private ShowBatchImagesPane(val: boolean) {
    this.showCustomCategoryBatchImagesPane = val;
  }
  // To add/update folder to number of selected items
  private ShowAssignToFolderBatchPane(val: boolean) {
    this.showAssignToFolderBatchPane = val;
  }
  // To add/update image/ icon to number of selected items
  private UpdateBulkImages(selectedBulkOperationModel: string) {
    const selectedCustomCategoryIds: number[] = this.signalR.GetSelectedCustomCategoryIdsToSendInJson(
      this.selectedItems!,
    );
    this.localSelectedBulkOperationModel.SelectedIds = selectedCustomCategoryIds;
    this.localSelectedBulkOperationModel.JSONData = selectedBulkOperationModel;
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    this.localSelectedBulkOperationModel.Heading = this.$t(
      this.localSelectedBulkOperationModel.Heading,
    ).toString();
    this.localSelectedBulkOperationModel.Description = this.$t(
      this.localSelectedBulkOperationModel.SubmitMessage,
      {
        selectedCount: this.selectedItems!.length,
        user: this.signalR.GetUserName(
          this.localSelectedBulkOperationModel,
          this.$t('UserName.You').toString(),
        ),
      },
    ).toString();
    this.signalR.PerformCustomCategoryBatchOperation(
      this.localSelectedBulkOperationModel,
    );
    Root.emit('customCategory-uncheck-select-all');
  }
  @Watch('selectedItems')
  private onPropertyChanged() {
    this.selectedItemsIds = [];
    this.selectedItems!.forEach((element) => {
      this.selectedItemsIds.push(element.CustomCategoryId);
    });
  }
}
export default toNative(CustomCategoryBatchEditPane);


import { Component, Vue, toNative } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import { CustomCategoryViewModel } from '@/entities/CustomCategory/CustomCategoryViewModel';
import { CustomCategoryFolder, CustomCategoryFolderItemSignalR} from '@/entities/CustomCategory/CustomCategoryFolder';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { CustomCategoryItem, CustomCategoryItemSignalR} from '@/entities/CustomCategory/CustomCategoryItem';
import CustomCategoryListView from '@/components/CustomCategory/CustomCategoryDetail/CustomCategoryListView.vue';
import CustomCategoryBatchEditPane from '@/components/CustomCategory/CustomCategoryDetail/CustomCategoryBatchEditPane.vue';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import CustomCategoryFolderListView from '@/components/CustomCategory/CustomCategoryDetail/Folders/CustomCategoryFolderListView.vue';
import { FolderSignalRModel } from '@/signalRRequest/CustomCategory/FolderSignalRModel';
import CustomCategoryAddItem from '@/components/CustomCategory/AddProgram/CustomCategoryAddItem.vue';
import CustomCategoryParentEditPane from '@/components/CustomCategory/CustomCategoryDetail/CustomCategoryParentEditPane.vue';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { Root, Store, EnvironmentBaseURL, FetchData } from '@/main';
import { useRoute } from 'vue-router';
import UpdateFolderListing from '@/entities/Emits/Information/UpdateFolderListing';
import FreezeScreen from '@/entities/Emits/Common/FreezeScreen';
import { EventItem } from '@/entities/ApplicationInstance/EventItem';
import VueTabs from '@/components/Common/Tabs.vue';
import { CustomCategoryTabList } from '@/entities/CustomCategory/CustomCategoryTabList';

@Component({
  components: {
    loading,
    ModalPopup,
    CustomCategoryListView,
    CustomCategoryFolderListView,
    CustomCategoryBatchEditPane,
    CustomCategoryAddItem,
    CustomCategoryParentEditPane,
    VueTabs,
  },
})
class CustomCategory extends Vue {
  // readonly properties
  private readonly All: string = 'All';
  private readonly folders = 'folders';
  private readonly CreateNewCustomCategory = 'CreateNewCustomCategory';
  private readonly selectedCustomCategory = 'selectedCustomCategory';
  private readonly SelectedBatchCustomCategory = 'selectedBatchCustomCategory';
  private readonly SelectedCustomCategoryAddItem = 'SelectedCustomCategoryAddItem';
  private readonly WarningMessage = 'WarningMessage';

  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly InformationInspirationUrl = 'StaticContent/InspirationArea/admin-user-information-inspiration.html';
  // properties
  private classicMode = false;
  private showLoading = false;
  private loaderBorderColor = '';
  private viewModel?: CustomCategoryViewModel | null = null;
  private selectedCustomCategoryItems: CustomCategoryItem[] = [];
  private activeSubComponent = '';
  private selectAllCustomCategoryItems = false;
  private isMobileView = false;
  private applicationId = 0;
  private applicationInstanceId = 0;
  private localEditedCustomCategoryItem: any = null;
  private customCategoryEditPaneValues: any = null;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showSection_ModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private selectedCustomCategoryItemSignalR: CustomCategoryItemSignalR = CustomCategoryItemSignalR.createEmpty();
  private notificationSignalRModelRequest: SignalRModelRequest = new SignalRModelRequest();
  private selectedFolderItem: CustomCategoryFolder | null = null;
  private createNewClicked = false;
  private customCategoryTypeId = -1;
  private readonly NotificationCustomCategoryBulkUpdated =
    'NotificationCustomCategoryBulkUpdated';
  private searchPlaceholder = '';
  private searchTitle = '';
  private searchNoticeLineText = '';
  private isMultipleParticipant = false;
  private selectedCustomerTypeId = 0;
  private noParticipantFoundText = '';
  private hasParticipant = false;
  private participantsGroups: ParticipantGroups[] = [];
  private participantGetListCount = 250;
  private participantsCount = 0;
  private showSectionModalPopup = 'Unsaved';
  private createNewUnsavedFlag = false;
  private tabViewModel: CustomCategoryTabList = new CustomCategoryTabList();
  private addIcon = require('@/assets/Images/white-add-icon.svg');
  private get showCustomCategoryAddItem(): boolean {
    if(this.selectedCustomCategoryItems.length === 1 && this.createNewClicked === true && this.selectAllCustomCategoryItems === false) {
      return true;
    }
    return false;
  }
  private get showCustomCategoryParentEditPane(): boolean {
    if((this.selectedCustomCategoryItems.length === 1) && this.createNewClicked === false) {
      return true;
    }
    return false;
  }
  // #region CustomCategory


  private mounted() {
    this.showLoading = true;
    this.GetRequestInfoFromStore();
    const router = useRoute();
    if (this.$route.params.applicationborderColor != null) {
      this.loaderBorderColor = router.params.applicationborderColor as string;
    }
    FetchData('/CustomCategory/GetCustomCategoriesViewModel', '')
      .then((response) => {
        const copyViewModel = response;
        this.viewModel = copyViewModel;
        this.participantsGroups = this.viewModel!.ParticipantGroups;
        this.viewModel!.Folders.unshift(this.All);
        this.LoadEventsData();
        this.showLoading = false;
        this.participantsCount = response.ParticipantsCount;
      })
      .catch(() => {
        this.showLoading = false;
      });
    // check for mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    Root.on(
      'customCategory-item-edit-operation-with-notification',
      (notification: SignalRModelRequest) => {
        this.CustomCategoryItemEditedOperationWithNotification(notification );
      },
    );
    Root.on('customCategory-uncheck-select-all', () => {
      this.selectAllCustomCategoryItems = false;
    });
    Root.on(
      'customCategory-folder-item-delete-operation',
      (folderId: number) => {
        this.CustomCategoryFolderItemDeletedOperation(folderId );
      },
    );
    Root.on('updatedFolders', (folders: string[]) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Folders = folders ;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Folders.unshift(this.All);
    });
    Root.on(
      'customCategory-folder-item-edit-operation',
      (pt: CustomCategoryFolder) => {
        this.CustomCategoryFolderItemEditedOperation(pt );
      },
    );
    Root.on('reload-customCategories-data', () => {
      this.ReloadCustomCategoriesData(false);
    });
    this.ListenSignalRResponseEvents();
    // CustomCategory type on change
    Root.on('customCategory-type', (type: number) => {
      this.createNewClicked = false;
      this.customCategoryTypeId = (type );
    });
    Root.on('customCategory-cancel-clicked', () => {
      this.createNewClicked = true;
    });
    if(this.selectedCustomCategoryItems.length > 0) {
      let name: string = this.selectedCustomCategoryItems[0].ContentTypeV2.toString().toLowerCase();
      if(name === this.$t('CustomCategory.Sponsor').toString().toLowerCase()){
        name = this.$t('Representative').toString().toLowerCase();
        Root.on('customCategory-newItem-clicked', () => {
          this.createNewClicked = true;
          this.CreateNewCustomCategoryItem();
          this.setActiveSubComponent(this.SelectedCustomCategoryAddItem);
        });
      }
      this.searchNoticeLineText=this.$t('SearchNoticeLineText', { type: name }).toString();
    }
    Root.on('customCategory-newItem-clicked', () => {
      this.createNewClicked = true;
      this.CreateNewCustomCategoryItem();
      this.setActiveSubComponent(this.SelectedCustomCategoryAddItem);
    });
    Root.on('freeze-screen', (item: FreezeScreen) => {
      const userName = (item ).UserName;
      const val = (item ).IsScreenFreezed;
      const storeHelper: StoreHelper = new StoreHelper(Store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      if (reqInfo.Username === userName) {
        const progressBar = document.getElementById('right-inner-flex-container') as HTMLElement;
        if (progressBar !== null) {
          if (val === true) {
            progressBar.classList.add('show-right-panel');
          } else {
            progressBar.classList.remove('show-right-panel');
          }
        }
      }
    });
  }
  private ReloadCustomCategoriesData(showLoading: boolean) {
    if (showLoading) {
      this.showLoading = true;
    }
    FetchData('/CustomCategory/GetCustomCategoriesViewModel','')
      .then((response) => {
        const copyViewModel = response;
        this.viewModel = copyViewModel;
        this.viewModel!.Folders.unshift(this.All);
        this.LoadEventsData();
        this.showLoading = false;
        this.participantsCount = response.ParticipantsCount;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Get data of all events
  private LoadEventsData() {
    FetchData('/ApplicationInstance/GetApplicationInstances','')
      .then((response: EventItem[]) => {
        this.viewModel!.EventItems = response;
      })
      .catch(() => {
        // Do nothing
      });
  }
  // SignalR operations
  private ListenSignalRResponseEvents() {
    Root.on(
      'batch-selected-customCategory-items-completed',
      (val: SignalRModelRequest) => {
        const notification = val ;
        const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
          this.$root,
          Store,
          this.$t,
        );
        signalR.CustomCategorySignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          if (this.selectedCustomCategoryItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) =>
                obj === this.selectedCustomCategoryItems[0].CustomCategoryId,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationCustomCategoryBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              if (notification.SubModule === SubModuleEnum.Delete) {
                this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
                  username: notification.UserName,
                }).toString();
              } else {
                this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
                  username: notification.UserName,
                }).toString();
              }
            }
          } else if (this.selectedCustomCategoryItems.length > 1) {
            this.modalPopupVisible = true;
            this.showCloseButton = false;
            this.showSection_ModalPopup = this.NotificationCustomCategoryBulkUpdated;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
            this.modalPopupCancelButtonText = this.$t(
              'CancelButton',
            ).toString();
            this.modalPopupContent = this.$t('Message.MultipleItemsUpdatedBy', {
              username: notification.UserName,
            }).toString();
          } else {
            if (notification.SubModule === SubModuleEnum.AssignToFolder) {
              Root.emit('update-folder-in-listing');
            } else if (notification.SubModule === SubModuleEnum.LinkProgram) {
              Root.emit('update-program-in-listing');
            }
          }
        }
      },
    );
    // Any operation performed on folders
    Root.on(
      'selected-folders-item-notification-completed',
      (val: SignalRModelRequest) => {
        const notification = val ;
        if (notification.IsSuccess) {
          this.PerformFolderOperationOnList(notification);
        }

        const signalR: FolderSignalRModel = new FolderSignalRModel(
          this.$root,
          Store,
          this.$t,
        );
        signalR.FolderSignalRModelResponseReceived(notification);
      },
    );
    Root.on(
      'set-custom-category-landing-page',
      (showLandingPage: boolean) => {
        if (showLandingPage ) {
          this.SetCustomCategoryLandingPage();
        }
      },
    );
  }
  private PerformFolderOperationOnList(notification: SignalRModelRequest) {
    // For deletion of folder item
    if (
      notification.Module === ModuleEnum.InformationFolder &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedCustomCategoryItems.length === 1 &&
          this.selectedCustomCategoryItems[0].CategoryFolder != null &&
        this.selectedCustomCategoryItems[0].CategoryFolder.Id ===
          notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedFolderItemIsOpenInEditMode(notification);
        } else {
          this.CustomCategoryFolderItemDeletedOperation(notification.SelectedIds[0]);
        }
      }
    } else {
      // For addition or updation of folder item
      const folderSignalRItem: CustomCategoryFolderItemSignalR = JSON.parse(
        notification.JSONData,
      );
      let folderItem: CustomCategoryFolder = CustomCategoryFolder.createEmpty();
      // Filling empty folder item with received folder item from signalR
      folderItem = CustomCategoryFolder.GetFolderItemFromFolderItemSignalR(
        folderItem,
        folderSignalRItem,
      );
      // remove properties to avoid reset for another user if he is working on any folder.
      delete folderItem.OldIconUrl;
      delete folderItem.OldIconUrlOriginal;
      delete folderItem.OldName;
      delete folderItem.IsSelected;
      delete folderItem.OldFontAwesomeIconId;
      delete folderItem.OldFontAwesomeIconName;
      this.CustomCategoryFolderItemEditedOperationWithNotification(folderItem, notification);
    }
  }
  private GetIframeUrl(): string {
    return EnvironmentBaseURL + this.InformationInspirationUrl;
  }
  // Check if deleted folder item was in edit mode and logged in user is different
  private CheckIfDeletedFolderItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      this.$root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.CustomCategoryFolderItemDeletedOperation(notification.SelectedIds[0]);
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSection_ModalPopup = this.NotificationCustomCategoryBulkUpdated;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.FolderUpdatedBy', {
        username: notification.UserName,
      }).toString();
    }
  }
  // Addition/ updation of folder item received from EditCustomFolder API
  private CustomCategoryFolderItemEditedOperation(pt: CustomCategoryFolder) {
    const objIndex = this.viewModel!.CustomCategoryFolders.findIndex(
      (obj: CustomCategoryFolder) => obj.Id === pt.Id,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel!.CustomCategoryFolders.push(pt);
    } else {
      // Updated
      if(this.viewModel !== null && this.viewModel !== undefined) {
        this.viewModel.CustomCategoryFolders[objIndex] = pt;
      }
      // update folder in custom category
      this.viewModel!.CustomCategoryItems.forEach((element) => {
        if (element.CategoryFolder) {
          if (element.CategoryFolder.Id === pt.Id) {
            element.CategoryFolder.Name = pt.Name;
            element.CategoryFolder.IconUrl = pt.IconUrl;
            element.CategoryFolder.IconUrlOriginal = pt.IconUrlOriginal;
            element.CategoryFolder.PinnedToMenu = pt.PinnedToMenu;
            element.CategoryFolder.FontAwesomeIconId = pt.FontAwesomeIconId;
            element.CategoryFolder.FontAwesomeIconCode = pt.FontAwesomeIconCode;
            element.CategoryFolder.FontAwesomeIconName = pt.FontAwesomeIconName;
          }
        }
      });
      // update folder list in custom category listing
      Root.emit('update-folder-listing-dropdown', true);
    }
  }

  // eslint-disable-next-line max-len
  private CustomCategoryFolderItemEditedOperationWithNotification(pt: CustomCategoryFolder, notification: SignalRModelRequest) {
    const objIndex = this.viewModel!.CustomCategoryFolders.findIndex(
      (obj: CustomCategoryFolder) => obj.Id === pt.Id,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel!.CustomCategoryFolders.push(pt);
      Root.emit('close-folder-listing-edit-pane');
    } else {
      // Updated
      if(this.viewModel !== null && this.viewModel !== undefined) {
        this.viewModel.CustomCategoryFolders[objIndex] = pt;
      }
      // update folder in custom category
      this.viewModel!.CustomCategoryItems.forEach((element) => {
        if (element.CategoryFolder) {
          if (element.CategoryFolder.Id === pt.Id) {
            element.CategoryFolder.Name = pt.Name;
            element.CategoryFolder.IconUrl = pt.IconUrl;
            element.CategoryFolder.IconUrlOriginal = pt.IconUrlOriginal;
            element.CategoryFolder.PinnedToMenu = pt.PinnedToMenu;
            element.CategoryFolder.FontAwesomeIconId = pt.FontAwesomeIconId;
            element.CategoryFolder.FontAwesomeIconCode = pt.FontAwesomeIconCode;
            element.CategoryFolder.FontAwesomeIconName = pt.FontAwesomeIconName;
          }
        }
      });
      // update folder list in custom category listing
      const updatedFolderListing: UpdateFolderListing = UpdateFolderListing.createEmpty();
      updatedFolderListing.notification = notification;
      updatedFolderListing.pt = pt;
      Root.emit('update-folder-listing-dropdown-with-notification', updatedFolderListing);
    }
  }
  private CustomCategoryFolderItemDeletedOperation(folderId: number) {
    const objIndex = this.viewModel!.CustomCategoryFolders.findIndex(
      (obj: CustomCategoryFolder) => obj.Id === folderId,
    );
    if (objIndex > -1) {
      this.viewModel!.CustomCategoryFolders.splice(objIndex, 1);
      this.selectedFolderItem = null;
      // remove folder from CustomCategory List
      this.viewModel!.CustomCategoryItems.forEach((element) => {
        if (element.CategoryFolder) {
          if (element.CategoryFolder.Id === folderId) {
            this.viewModel!.Folders = this.viewModel!.Folders.filter(
              (e) => e !== element.CategoryFolder!.Name,
            );
            element.CategoryFolder = null;
          }
        }
      });
    }
  }
  // To removed this program item from program list.
  private customCategoryItemDeletedOperation(pi: CustomCategoryItem) {
    const objIndex = this.viewModel!.CustomCategoryItems.findIndex(
      (obj: CustomCategoryItem) => obj.CustomCategoryId === pi.CustomCategoryId,
    );
    if(this.viewModel !== null && this.viewModel !== undefined) {
      delete this.viewModel.CustomCategoryItems[objIndex];
    }
    this.selectedCustomCategoryItems = [];
    // to show Inspiration area
    this.activeSubComponent = '';
  }
  private customCategoryItemUpdateLinkedEvents(pi: CustomCategoryItem) {
    if (
      this.selectedCustomCategoryItems.length === 1 &&
        this.selectedCustomCategoryItems[0].CustomCategoryId ===
          pi.CustomCategoryId
    ) {
      const objIndex = this.viewModel!.CustomCategoryItems.findIndex(
        (obj: CustomCategoryItem) => obj.CustomCategoryId === pi.CustomCategoryId,
      );
      this.viewModel!.CustomCategoryItems[objIndex].LinkedEvents = pi.LinkedEvents;
    }

  }
  private CustomCategoryItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const pi: CustomCategoryItemSignalR = JSON.parse(notification.JSONData);
    const objIndex = this.viewModel!.CustomCategoryItems.findIndex(
      (obj: CustomCategoryItem) => obj.CustomCategoryId === pi.CustomCategoryId,
    );
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (objIndex === -1) {
      // Created
      const pItem = signalR.FillCustomCategoryObjectFromSignalRResponseNewItem(
        pi,
      );
      if(this.viewModel !== null && this.viewModel !== undefined) {
        this.viewModel.CustomCategoryItems.unshift(pItem);
      }
    } else {
      // Updated
      // Avoid override updated current program if edit pane is diffirent
      if (
        this.selectedCustomCategoryItems.length === 1 &&
        this.selectedCustomCategoryItems[0].CustomCategoryId ===
          pi.CustomCategoryId
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          pi,
        );
      } else {
        this.SetCustomCategoryItemInList(objIndex, signalR, pi);
      }
    }
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // get index of newly added customcategory item
      const newCustomCategoryItemIndex = this.viewModel!.CustomCategoryItems.findIndex(
        (obj: CustomCategoryItem) => obj.CustomCategoryId === pi.CustomCategoryId,
      );
      Root.emit('scroll-to-newly-added-customCategory-item', newCustomCategoryItemIndex);
    }
  }

  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSection_ModalPopup === this.folders) {
        this.CreateNewCustomCategoryFolderItem();
      } else if (this.showSection_ModalPopup === this.CreateNewCustomCategory) {
        this.CreateNewCustomCategoryItem();
      } else if (this.showSection_ModalPopup === this.NotificationUpdated) {
        this.ReloadConfirmedCustomCategoryItem();
        return;
      } else if (
        this.showSection_ModalPopup ===
        this.NotificationCustomCategoryBulkUpdated
      ) {
        // update folders in information items edit pane
        this.ReloadCustomCategoriesData(false);
        if (this.selectedCustomCategoryItems.length === 1) {
          this.customCategoryItemCancelOperation();
        } else if (this.selectedCustomCategoryItems.length > 1) {
          this.SetCustomCategoryLandingPage();
        }
      }
      Root.emit('clearCustomCategoryCheckboxes', []);
    } else {
      return;
    }
  }
  private SetCustomCategoryLandingPage() {
    if (this.selectedCustomCategoryItems.length > 0) {
      this.selectAllCustomCategoryItems = false;
      this.selectedCustomCategoryItems = [];
      Root.emit('clearCustomCategoryCheckboxes', []);
    }
    this.setActiveSubComponent('');
  }

  private ReloadConfirmedCustomCategoryItem() {
    const pq: CustomCategoryItemSignalR = this
      .selectedCustomCategoryItemSignalR;
    const objIndex = this.viewModel!.CustomCategoryItems.findIndex(
      (obj: CustomCategoryItem) => obj.CustomCategoryId === pq.CustomCategoryId,
    );
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      this.$root,
      Store,
      this.$t,
    );

    this.PerformSelectedItemUpdateOperation(
      this.SetCustomCategoryItemInList(objIndex, signalR, pq),
    );
    Root.emit('reload-customCategory-detail');
  }

  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: CustomCategorySignalRModel,
    objIndex: number,
    pi: CustomCategoryItemSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.PerformSelectedItemUpdateOperation(
        this.SetCustomCategoryItemInList(objIndex, signalR, pi),
      );
    } else {
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedCustomCategoryItemSignalR = pi;
      this.notificationSignalRModelRequest = notification;
    }
  }
  // Update the selected information item
  private PerformSelectedItemUpdateOperation(cItem: CustomCategoryItem) {
    this.updateCustomCategoryItemComponent(cItem);
    this.selectedCustomCategoryItems = [cItem];
  }
  private SetCustomCategoryItemInList(
    objIndex: number,
    signalR: CustomCategorySignalRModel,
    pi: CustomCategoryItemSignalR,
  ): CustomCategoryItem {
    const pItem = signalR.FillCustomCategoryObjectFromSignalRResponse(
      pi,
      objIndex,
      this.viewModel!.CustomCategoryItems,
    );
    if(this.viewModel !== null && this.viewModel !== undefined) {
      this.viewModel.CustomCategoryItems[objIndex] = pItem;
    }
    return pItem;
  }
  // Navition to other information item and checking for unsaved changes
  private ChangeCustomCategoryItem(object: any) {
    this.createNewClicked = false;
    const parentRef = this.$refs.parent! as any;
    const child = parentRef.$refs.EditCustomCategoryItem;
    if (child) {
      // get current values of program edit pane
      this.customCategoryEditPaneValues = Object.assign(
        {},
        child.localCustomCategoryItem,
      );
      // to prevent watch of localEditedCustomCategoryItem if create new customCategory is click.
      if (!object.moveToAnotherTab) {
        this.localEditedCustomCategoryItem = this.customCategoryEditPaneValues;
      }
    } else {
      this.localEditedCustomCategoryItem = object.selectedItem;
    }
  }
  private GetMoveToAnotherItemMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }

  private updateCustomCategoryItemComponent(
    selectedCustomCategoryItem: CustomCategoryItem,
  ) {
    Root.emit(
      'UpdateEditorDescription',
      selectedCustomCategoryItem.Description,
    );
    // make image process component false if other program item selected
    Root.emit('show-image-process', false);
  }

  private CustomCategoryItemsSelected(pItems: CustomCategoryItem[]) {
    const copyArr = Array.from(pItems); // copy array.
    // Set selected item collection for batch operations
    this.selectedCustomCategoryItems = copyArr;
    if (this.selectedCustomCategoryItems.length === 1) {
      this.updateCustomCategoryItemComponent(
        this.selectedCustomCategoryItems[0],
      );
      this.setActiveSubComponent(this.selectedCustomCategory);
    } else if (this.selectedCustomCategoryItems.length > 1) {
      this.setActiveSubComponent(this.SelectedBatchCustomCategory);
    } else if (this.selectedFolderItem != null) {
      this.setActiveSubComponent(this.folders);
    } else {
      this.setActiveSubComponent('');
    }
  }
  // Set activeSubComponent
  private setActiveSubComponent(comp: string) {
    this.activeSubComponent = comp;
  }
  // Get data from store
  private GetRequestInfoFromStore() {
    const storeHelper: StoreHelper = new StoreHelper(Store);
    setTimeout(() => {
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      this.applicationId = Number(reqInfo.ApplicationId);
      this.applicationInstanceId = Number(reqInfo.ApplicationInstanceId);
    }, 100);
  }
  // Switch to classic mode
  private SwitchClassicMode() {
    this.classicMode = true;
    window.location.href =
      EnvironmentBaseURL + '/Admin/CustomCategory/IndexCustomCategory';
  }
  // #endregion

  // #region CustomCategoryItem
  private btnCreateNewCustomCategoryItemClick() {
    Root.emit('from-createnew', true);
    if (this.selectedCustomCategoryItems.length === 0 || this.selectedCustomCategoryItems.length > 1) {
      this.createNewClicked = true;
      this.CreateNewCustomCategoryItem();
      this.setActiveSubComponent(this.SelectedCustomCategoryAddItem);
    } else{
      const currntItemId = this.selectedCustomCategoryItems[0].CustomCategoryId;
      this.viewModel!.CustomCategoryItems.forEach((item) => {
        if (item.CustomCategoryId === currntItemId) {
          this.selectedCustomCategoryItems[0] = item;
        }
      });
      const parentRef = this.$refs.parent! as any;
      const child = parentRef.$refs.EditCustomCategoryItem;
      if (child) {
      // get current values of program edit pane
        this.customCategoryEditPaneValues = Object.assign(
          {},
          child.localCustomCategoryItem,
        );
        // to prevent watch of localEditedCustomCategoryItem if create new customCategory is click.
        // this.localEditedCustomCategoryItem = this.customCategoryEditPaneValues;
        this.selectedCustomCategoryItems[0].CategoryFolder =  this.selectedCustomCategoryItems[0].CategoryFolder === undefined ? this.customCategoryEditPaneValues.CategoryFolder : this.selectedCustomCategoryItems[0].CategoryFolder;
        const $this = this;
        if (
          CustomCategoryItem.prototype.CompareCustomCategoryObjects({
            val1: this.selectedCustomCategoryItems[0],
            val2: this.customCategoryEditPaneValues,
          })
        ) {
          this.createNewClicked = true;
          this.CreateNewCustomCategoryItem();
          this.setActiveSubComponent(this.SelectedCustomCategoryAddItem);
          this.createNewUnsavedFlag =  true;
          // setTimeout(() => {
          //   // $this.localEditedCustomCategoryItem = $this.customCategoryEditPaneValues;
          // }, 100);

        } else {
          this.createNewUnsavedFlag = false;
          setTimeout(() => {
            $this.localEditedCustomCategoryItem = $this.customCategoryEditPaneValues;
          }, 100);
        }
      }
    }
  }
  private CreateNewCustomCategoryItem() {
    if (this.selectedCustomCategoryItems.length > 0) {
      this.selectAllCustomCategoryItems = false;
      setTimeout(() => {
        this.CreateCustomCategory();
      }, 100);
    } else {
      this.CreateCustomCategory();
    }
  }
  private CreateCustomCategory() {
    this.setActiveSubComponent(this.SelectedCustomCategoryAddItem);
    const newItem = CustomCategoryItem.createEmpty();
    this.selectedCustomCategoryItems = [newItem];
    this.selectedFolderItem = null;
    Root.emit('createEmptyCustomCategoryItem', newItem);
    Root.emit('clearDescription', '');
    Root.emit('clearCustomCategoryMultimedia');
    Root.emit('clearCustomCategoryImage360');
    Root.emit('clearPrograms', []);
    this.createNewClicked = true;
  }

  private customCategoryItemCancelOperation() {
    this.setActiveSubComponent('');
    this.selectedCustomCategoryItems = [];
  }
  // update order of information item in listing screen
  private UpdateSortingCustomCategoryListing(data: any) {
    const filteredCustomCategoryListing: CustomCategoryItem[] =
      data.filterCustomCategoryItems;
    const signalObject: SignalRModelRequest = data.signalObject;
    for (const customCategory of filteredCustomCategoryListing) {
      const objIndex = this.viewModel!.CustomCategoryItems.findIndex(
        (obj: CustomCategoryItem) =>
          obj.CustomCategoryId === customCategory.CustomCategoryId,
      );
      if(this.viewModel !== null && this.viewModel !== undefined) {
        this.viewModel.CustomCategoryItems[objIndex] = customCategory;
      }
    }

    this.viewModel!.CustomCategoryItems.sort(
      (a: CustomCategoryItem, b: CustomCategoryItem): number => {
        if (a.SortOrder > b.SortOrder) {
          return 1;
        }
        if (b.SortOrder > a.SortOrder) {
          return -1;
        }
        if (a.SortOrder === b.SortOrder) {
          return a.Title > b.Title ? 1 : a.Title === b.Title ? 0 : -1;
        }
        return 0;
      },
    );
    // call signalR
    this.SendNotificationRequestToQueue(
      signalObject,
      this.viewModel!.CustomCategoryItems,
    );
  }
  // #endregion
  // #region SignalR
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    customCategoryItems: CustomCategoryItem[],
  ) {
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
      this.$root,
      Store,
      this.$t,
    );
    signalObject.JSONData = JSON.stringify(customCategoryItems);
    signalR.PerformCustomCategorySingleOperation(signalObject);
  }
  // #endregion

  // #region CustomCategory Folders
  private btnCreateNewCustomCategoryFolderClick() {
    if (this.selectedCustomCategoryItems.length === 1) {
      this.CreateNewCustomCategoryFolderItem();
    } else {
      this.CreateNewCustomCategoryFolderItem();
    }
  }
  private CreateNewCustomCategoryFolderItem() {
    this.setActiveSubComponent(this.folders);
    const newItem = CustomCategoryFolder.createEmpty();
    this.selectedFolderItem = newItem;
    this.selectedCustomCategoryItems = [];
    this.selectAllCustomCategoryItems = false;
    Root.emit('clearCustomCategoryCheckboxes', []);
  }
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  private CustomCategoryFolderItemCancelOperation() {
    this.setActiveSubComponent('');
    this.selectedFolderItem = null;
    this.selectedCustomCategoryItems = [];
    this.createNewClicked = false;
  }

  // #endregion

  private beforeUnmount() {
    Root.off('batch-selected-customCategory-items-completed');
    Root.off('selected-folders-item-notification-completed');
    Root.off('reload-customCategories-data');
  }
}
export default toNative(CustomCategory);

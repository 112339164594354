
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { CustomCategoryTypes } from '@/entities/CustomCategory/CustomCategoryType';
import { Root, Store } from '@/main';
@Component
class CustomCategoryAddItem extends Vue {

  private isMobileView = false;
  private isiPadView = false;
  private CustomCategoryTypes: any;
  private created() {
    this.GetData();
  }
  private mounted(){
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
      this.isiPadView = false;
    } else if (window.innerWidth <= 1100) {
      this.isMobileView = false;
      this.isiPadView = true;
    } else {
      this.isMobileView = false;
      this.isiPadView = false;
    }
  }
  private GetData() {
    this.CustomCategoryTypes = new CustomCategoryTypes(
      Root,
      Store,
      this.$t,
    );
  }
  // Check the given index number is even or not
  private IsEvenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  // Create New Custom Category Item Types by type id
  private CreateNewCustomCategoryItemTypes(typeId: number) {
    Root.emit('customCategory-type', typeId);
    setTimeout(() => {
      Root.emit('customCategoryDetailLoadingVod', false);
      Root.emit('customCategoryNewAdded', false);
    }, 1000);
  }
  // Close the add item component emit customCategory-folder-close-operation
  private Close() {
    this.$emit('customCategory-folder-close-operation');
  }
}
export default toNative(CustomCategoryAddItem);
